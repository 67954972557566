import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import axios from 'axios'
import { Grid, Button, Chip, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { getAuthToken } from "../../context/auth";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { withRouter } from "react-router-dom";
import AlterUserInModal from "./AlterUserInModal";
import WarningIcon from '@mui/icons-material/Warning';
import NewUser from "./NewUser";
import AutoClosingAlert from "./AutoClosingAlert";
import imageLoading from "../../images/logoAzul.png"
const token = getAuthToken();

function Users(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [dataUser, setDataUser] = useState({
    userId: "",
    name: "",
    email: "",
    entity: "",
    profile: "",
    profile_id: "",
    entities_id: ""
  });
  const [deleteUser, setDeleteUser] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [alterUse, setAlterUse] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [datatableData, setDataTableData] = useState([]);
  const [createAction, setCreateAction] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const handleCreateUserClick = () => {
    setCreateUser(true)
    toggleModal('create'); // Indique que é uma ação de criação
  };

  const toggleModal = (actionType) => {
    setModalOpen(!isModalOpen);
    setCreateAction(actionType === 'create');
    if(isModalOpen){
      setAlterUse(false)
      setCreateUser(false)
    }
 
  };
  const showProgressAlert = (message, duration) => {
    setAlertMessage(message);
    setShowAlert(true);
  
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, duration);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteUserId(id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    setDeleteUser("")
  };

  const handleDelete = async () => {
    try {
      const config = {
        method: 'delete',
        url: `//dispositivos.ctdlondrina.com.br/api/users/${deleteUserId}`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      };

      const response = await axios(config);

      if (response) {
        showProgressAlert("Usuário Excluido com sucesso.", 5000);
        fetchTableData();
      }
    } catch (error) {
      console.error('Erro ao fazer a requisição do relatório à API:', error);
    } finally {
      setConfirmDialogOpen(false);
      
    }
  };
  const renderUpdateButton = (value, tableMeta) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setAlterUse(true)
          setDataUser({
            userId: tableMeta.rowData[0],
            name: tableMeta.rowData[1],
            email: tableMeta.rowData[2],
            entity: tableMeta.rowData[3],
            profile: tableMeta.rowData[4],
          });
          toggleModal('update'); // Indique que é uma ação de alteração
        }}
      >
        Alterar Usuário
      </Button>
    );
  };
  useEffect(() => {
    const token = getAuthToken();
    const fetchReportData = async () => {
      setLoading(true)
      try {
        const config = {
          method: 'get',
          url: "//dispositivos.ctdlondrina.com.br/api/users/",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        };

        const response = await axios(config);
        const apiData = response.data;
        // console.log(response.data)
        const datatable = Object.values(apiData);
        setDataTableData(datatable)
        setLoading(false)
      } catch (error) {
        console.error('Erro ao fazer a requisição do relatório à API:', error);
      }
    };
    fetchReportData()
  }, []);

  async function fetchTableData() {
    try {
      setLoading(true)
      const config = {
        method: 'get',
        url: "//dispositivos.ctdlondrina.com.br/api/users/",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      };

      const response = await axios(config);
      const apiData = response.data;
      const datatable = Object.values(apiData);
      
      if(alterUse){
        showProgressAlert("Usuário Alterado com sucesso.", 5000);
      }else if(createUser){
        showProgressAlert("Usuário Criado com sucesso.", 5000);
      }
      setAlterUse(false)
      setDataTableData(datatable)
      setConfirmDialogOpen(false); // Adicione esta linha para garantir que o diálogo de confirmação seja fechado
     setDeleteUser(""); // Adicione esta linha para reinicializar o usuário a ser excluído
     setLoading(false)
    } catch (error) {
      console.error('Erro ao fazer a requisição do relatório à API:', error);
    }
  };
    // console.log(confirmDialogOpen)
  const randleDeleteUser = async (id, name) => {
    handleDeleteConfirmation(id);
  };

  const renderDeleteButton = (value, tableMeta) => {
    const id = tableMeta.rowData[0];
    const name = tableMeta.rowData[1];
    
    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          randleDeleteUser(id, name)
          setDeleteUser(name)
        }}
      >
        Excluir Usuário
      </Button>
    );
  };

  const columns = [
    { name: "Id", options: { center: true, display: false } },
    { name: "Nome", options: { center: true } },
    { name: "Email", options: { center: true, filter: false } },
    { name: "Entidade", options: { center: true } },
    {
      name: "Perfil",
      options: {
        customBodyRender: (value) => {
          const color = value === "Administrador" ? "primary" : "secondary";
          return <Chip label={value} color={color} />;
        },
        center: true,
      },
    },
    {
      name: "Alterar",
      options: {
        filter: false,
        customBodyRender: renderUpdateButton, // Adicione esta linha
        center: true,
      },
    },
    {
      name: "Excluir",
      options: {
        filter: false,
        customBodyRender: renderDeleteButton,
        center: true,
      },
    },
  ];

  const options = {
    customToolbar: () => {
      return (
        <>
          <Tooltip title={"Criar Usuário"}>
            <IconButton onClick={handleCreateUserClick}>
              <GroupAddIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    filterType: "checkbox",
    download: false,
    print: "false",
    headerStyle: {
      textAlign: "center",
    },
    filter: "false",
    selectableRows: "none",
    expandableRows: false
  };
  
  return (
    <>
    {showAlert && (
  <AutoClosingAlert
    message={alertMessage}
    duration={5000} // Ajuste a duração conforme necessário
    onClose={() => setShowAlert(false)}
  />
)}
      <Grid item xs={12}>
      {loading ? (
          <div className={classes.loadingContainer}>
          <div className={classes.loadingContent}>
            <img src={imageLoading} alt="Loading" className={classes.flippedImage} />
            <span className={classes.loadingText}>Carregando Novos Dados...</span>
          </div>
        </div>
      ) : (
        // Mostrar a tabela quando o carregamento estiver concluído
        <MUIDataTable
          title="Listagem de Usuários"
          data={datatableData.map((user) => [
            user.id,
            user.name,
            user.email,
            user.entity_name,
            user.profile,
          ])}
          columns={columns}
          options={options}
        />
      )}
      </Grid>

      <Dialog
  open={confirmDialogOpen}
  onClose={handleConfirmDialogClose}
  className={classes.confirmDialog} // Adicione esta linha
>
  <DialogTitle className={classes.confirmDialogTitle} id="alert-dialog-title">
    <WarningIcon className={classes.confirmDialogIcon} />
    Confirmação de Exclusão
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      {`Confirma a exclusão do usuário ${deleteUser}?`}
    </DialogContentText>
  </DialogContent>
  <DialogActions className={classes.confirmDialogActions}>
    <Button onClick={handleConfirmDialogClose} color="primary">
      Cancelar
    </Button>
    <Button onClick={handleDelete} color="primary" autoFocus>
      Confirmar
    </Button>
  </DialogActions>
</Dialog>

<AlterUserInModal
  userId={dataUser.userId}
  open={isModalOpen && !createAction} // Exibe apenas se não for uma ação de criação
  onClose={toggleModal}
  onChangesSaved={fetchTableData}
/>
<NewUser
  open={isModalOpen && createAction} // Exibe apenas se for uma ação de criação
  onClose={toggleModal}
  onChangesSaved={fetchTableData}
/>

    </>
  );
}

export default withRouter(Users);