import React, { useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close'; // Importe o CloseIcon
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "../../images/markup.png";
import moment from 'moment-timezone';
import imageLoading from "../../images/logoAzul.png";
import useStyles from "./styles";

const MapInModal = ({ latitude, longitude, open, onClose, apiLoading, dateLocalition }) => {
  const convertTimestampToSaoPauloTime = (timestamp) => {
    const saoPauloTime = moment(timestamp).tz('America/Sao_Paulo');
    return saoPauloTime;
  };
  const classes = useStyles();

  useEffect(() => {
    let map;

    const loadMap = async () => {
      try {
        setTimeout(() => {
          const mapContainer = document.getElementById("map-in-modal");

          if (mapContainer) {
            if (map) {
              map.remove();
            }
            console.log({latitude,longitude})
            map = L.map(mapContainer).setView([latitude, longitude], 15);

            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
              attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }).addTo(map);

            const customIcon = L.icon({
              iconUrl: icon,
              iconSize: [60, 40],
            });

            L.marker([latitude, longitude], { icon: customIcon }).addTo(map);
          }
        }, 200);
      } catch (error) {
        console.error('Erro ao renderizar o mapa:', error);
      }
    };

    if (open) {
      loadMap();
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [latitude, longitude, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {!apiLoading ? `Localização: Ultima Atualização ${convertTimestampToSaoPauloTime(dateLocalition).format('DD/MM/YYYY HH:mm:ss')}` : ''}
        <CloseIcon onClick={onClose} className={classes.closeIcon} /> {/* Adicione o CloseIcon */}
      </DialogTitle>
      <DialogContent>
        {apiLoading ? (
          <div className={classes.loadingContainer}>
            <div className={classes.loadingContent}>
              <img src={imageLoading} alt="Loading" />
              <span className={classes.loadingText}>Carregando Localização no Mapa...</span>
            </div>
          </div>
        ) : (
          <div id="map-in-modal" style={{ height: '600px', width: '100%' }}></div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MapInModal;