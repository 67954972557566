import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiInstagram as FacebookIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";

// pages
import Dashboard from "../../pages/dashboard";
import Users from "../../pages/users/Users";
// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
         
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/users" component={Users} />
              </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://www.ctdlondrina.com.br/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  CTD Companhia de Tecnologia e Desenvolvimento
                </Link>
                <Link
                  color={'primary'}
                  href={'https://www.ctdlondrina.com.br/ctd'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Sobre
                </Link>
                <Link
                  color={'primary'}
                  href={'https://suporte.ctdlondrina.com.br/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Sistema de Chamados
                </Link>
              </div>
              <div>
                <Link
                  href={'https://www.instagram.com/ctdciatecnologia/'}
                  target={'_blank'}
                >
                  <IconButton aria-label="Instagram">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
