import axios from 'axios';
import React from 'react';
import {setAuthToken} from './auth';
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS': {
      const isAdmin = action.profile_name === 'Administrador';
      // Salvando o estado no localStorage
      localStorage.setItem('userState', JSON.stringify({ ...state, isAuthenticated: true, isAdmin }));
      return {
        ...state,
        isAuthenticated: true,
        loginError: false,
        token: action.token,
        isAdmin,
      };
    }
    case 'SIGN_OUT_SUCCESS':
      // Removendo o estado do localStorage
      localStorage.removeItem('userState');
      return { ...state, isAuthenticated: false, loginError: false, token: null, isAdmin: false };
    case 'LOGIN_FAILURE':
      return { ...state, isAuthenticated: false, loginError: true, token: null, isAdmin: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  // Recuperando o estado do localStorage
  var [state, dispatch] = React.useReducer(userReducer, JSON.parse(localStorage.getItem('userState')) || {
    isAuthenticated: !!localStorage.getItem('id_token'),
    loginError: false,
    isAdmin: false,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
   if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

async function loginUser(dispatch, login, password, history, setIsLoading) {
  setIsLoading(true);

  if (!!login && !!password) {
    try {
      const response = await axios.post("//dispositivos.ctdlondrina.com.br/api/users/login", { login, password });
      if (response.data && response.data.token) {
        setAuthToken(response.data.token);
        dispatch({ type: 'LOGIN_SUCCESS', token: response.data.token, profile_name: response.data.profile_name });
      
        if (response.data.profile_name === 'Administrador') {
          history.push('/app/dashboard');
        } else {
          history.push('/app/dashboard');
        }
      } else {
        dispatch({ type: 'LOGIN_FAILURE' });
      }
    } catch (error) {
      dispatch({ type: 'LOGIN_FAILURE', error });
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem('id_token');
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push('/login');
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };