import React, { useState, useEffect } from "react";
import logoEmpresa from '../../images/logo-GLPI-100-white.png';
import { useHistory } from 'react-router-dom';
import axios from "axios"
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Link } from 'react-router-dom'; // Importe o componente Link
import {
  Person as AccountIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { 
  // Badge, 
  Typography, 
  } from "../Wrappers";

// context
import {
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut,useUserState } from "../../context/UserContext";
import CurrentUser from "../../pages/users/CurrentUser";
import { getAuthToken } from "../../context/auth";
export default function Header(props) {
  var classes = useStyles();
  var { isAdmin } = useUserState();
  // global
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [userProfile, setUserProfile] = useState([]);
  var [profileMenu, setProfileMenu] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  
  useEffect(() => {
    const token = getAuthToken();
    const fetchReportData = async () => {
      try {
        const config = {
          method: 'get',
          url: "//dispositivos.ctdlondrina.com.br/api/users/profile/",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        };

        const response = await axios(config);
        const apiData = response.data;
        const userData = Object.values(apiData);
        setUserProfile(userData)
      } catch (error) {
        console.error('Erro ao fazer a requisição do relatório à API:', error);
      }
    };
    fetchReportData()
  }, []);
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
   };
  // console.log(userProfile)
 return (
  <div>
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
      <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {/* {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )} */}
        </IconButton>
        <Link to="/app"> {/* Use o componente Link para criar um link */}
          <img src={logoEmpresa} alt="Logo da Minha Empresa" className={classes.logo} />
        </Link>
        <div className={classes.grow} />   
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
 
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
            {userProfile && userProfile[0] && userProfile[0].name
        ? userProfile[0].name
        : ''}
            </Typography>
            <Typography
              component="a"
              color="primary"
            >
              {userProfile && userProfile[0] && userProfile[0].entity_name
        ? userProfile[0].entity_name
        : ''}
            </Typography>
          </div>

          <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                onClick={() => {
                  setModalOpen(true)
                }}
              >
                {/* Se necessário, você pode trocar o ícone abaixo */}
                <AccountIcon className={classes.profileMenuIcon} /> Editar Perfil
              </MenuItem>
          {isAdmin && (
            <div>
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                onClick={() => {
                  // Navegue para a rota /app/users
                  history.push('/app/users');
                }}
              >
                {/* Se necessário, você pode trocar o ícone abaixo */}
                <AccountIcon className={classes.profileMenuIcon} /> Usuários
              </MenuItem>
            </div>
          )}
                    <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sair
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
    <>
      <CurrentUser
        userId={userProfile && userProfile[0] && userProfile[0].id
          ? userProfile[0].id
          : ''}
        open={isModalOpen}
        onClose={toggleModal}
     />
    </>
  </div>
);
}
