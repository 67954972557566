import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  MenuItem,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import axios from 'axios'
import useStyles from "./styles";
import { getAuthToken } from "../../context/auth";
import imageLoading from "../../images/logoAzul.png"
const NewUser = ({ open, onClose, onChangesSaved }) => {
  const classes = useStyles();

  // Estados para armazenar os valores modificados
  const [loading, setLoading] = useState(false)
  const [dataUser, setDataUser] = useState("")
  const [dataEntities, setDataEntities] = useState([])
  const [selectedProfile, setSelectedProfile] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  

  const handleCreateUser = async () => {
    const token = getAuthToken();
    // Limpa mensagens de erro anteriores
    setErrorMessages([]);
  
      
          const dataToCreateUser = {
            name: dataUser,
            email: userEmail,
            entities: selectedEntity,
            profile: selectedProfile,
            password: password,
            confirmPassword: confirmPassword,
            // Adicione outros campos conforme necessário
          };
        //   console.log(dataToCreateUser)
          const config = {
            url: '//dispositivos.ctdlondrina.com.br/api/users/register',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          };
  
          axios.post(config.url, dataToCreateUser, config)
          .then(async (response) => {
            // Manipule a resposta da API conforme necessário
            // console.log('Resposta da API:', response.data);
            setPassword("")
            setConfirmPassword("")
            setUserEmail("")
            setDataUser("")
            setSelectedProfile("")
            setSelectedEntity("")
            try {
              // Tente fechar o modal
              await onClose();
              onChangesSaved();
              console.log('Modal fechado com sucesso');
            } catch (error) {
              console.error('Erro ao fechar o modal:', error);
            }
          })
          .catch((error) => {
            // Manipule os erros da API e atualize mensagens de erro
            console.error('Erro na chamada da API:', error);
            if (error.response && error.response.data && error.response.data.errors) {
              setErrorMessages(error.response.data.errors);
            } else {
              setErrorMessages(["Erro ao processar a solicitação. Tente novamente mais tarde."]);
            }
          });
      
     
   
  };
  useEffect(() => {

    const fetchData = async (url, method, setStateFunction) => {
      const token = getAuthToken();
      // console.log(`Fetching data from ${url} with method ${method}`);
      
      try {
        const config = {
          method,
          url: url,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios(config);
        const apiData = response.data;
        // Verifique se a URL contém a string desejada
          
          setStateFunction(apiData);
       
      } catch (error) {
        console.error('Erro ao fazer a requisição à API:', error);
      }
    };
   
    const fetchAllData = async () => {
      
      setLoading(true);

      try {
        const entitiesUrl = '//dispositivos.ctdlondrina.com.br/api/devices/entities';
        await fetchData(entitiesUrl, "get", setDataEntities);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();

  }, [open]);

  
   // Função para lidar com a atualização
   const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

 
  
  // console.log("Loading:", loading);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Inserir Usuário</DialogTitle>
      <div className={classes.modalContent}>
        {/* Se loading for verdadeiro, mostra o indicador de loading */}
        {loading ? (
          <div className={classes.loadingContainer}>
            <div className={classes.loadingContainer}>
              <div className={classes.loadingContent}>
                <img src={imageLoading} alt="Loading" className={classes.flippedImage} />
                <span className={classes.loadingText}>Carregando...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
         {errorMessages.length > 0 && (
  <div className={classes.errorContainer}>
    {errorMessages.map((errorMessage, index) => (
      <div key={index} className={classes.errorItem}>
        <span className={classes.errorIcon}>⚠️</span>
        <span className={classes.errorMessage}>{errorMessage}</span>
      </div>
    ))}
  </div>
)}
 <TextField
  id="email"
  value={userEmail}
  onChange={(e) => {
    setUserEmail(e.target.value);
}}
  
  margin="normal"
  label="E-mail"
  fullWidth
  className={`${classes.modalInput} ${classes.centeredText}`}
/>
<TextField
  id="name"
  value={dataUser}
  onChange={(e) => {
    setDataUser(e.target.value);
}}
  
  margin="normal"
  label="Nome"
  fullWidth
  className={`${classes.modalInput} ${classes.centeredText}`}
/>

<TextField
        id="password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        
        }}
        margin="normal"
        label="Senha"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        id="confirmPassword"
        type={showConfirmPassword ? 'text' : 'password'}
        value={confirmPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
    
        }}
        margin="normal"
        label="Confirmar Senha"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={handleToggleConfirmPasswordVisibility}
                edge="end"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

<TextField
  id="profile"
  select
  label="Perfil"
  value={selectedProfile}
  onChange={(e) => {
    setSelectedProfile(e.target.value);
  }}
  margin="normal"
  fullWidth
  className={classes.modalInput}
  InputLabelProps={{ shrink: selectedProfile !== '' }}
>
  <MenuItem value="Administrador">Administrador</MenuItem>
  <MenuItem value="Usuario">Usuário</MenuItem>
</TextField>

<TextField
  id="entity"
  select
  label="Entidade"
  value={selectedEntity}
  onChange={(e) => {
    setSelectedEntity(e.target.value);
  }}
  margin="normal"
  fullWidth
  className={classes.modalInput}
  InputLabelProps={{ shrink: selectedEntity !== '' }}
>
  {dataEntities.map((entity) => (
    <MenuItem key={entity.id} value={entity.name}>
      {entity.name}
    </MenuItem>
  ))}
</TextField>
          </>
        )}
      </div>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button onClick={handleCreateUser} color="primary">
          Criar Usuário
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewUser;