import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import classnames from "classnames";

// styles
import useStyles from "./styles";

const customButtonStyle = {
  width: "24px", // Defina a largura do botão conforme necessário
  height: "24px", // Defina a altura do botão conforme necessário
};

export default function Widget({
  children,
  title,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  header,
  noHeaderPadding,
  headerClass,
  style,
  data,
  noWidgetShadow,
  onEscolaSelect, // Função para lidar com a seleção da escola
  ...props
}) {
  var classes = useStyles();
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  var [escolas, setEscolas] = useState([]);
  const [termoBusca] = useState('');
  const listaRef = useRef(null);

  useEffect(() => {
    let school = [];
    if (data != null && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        school.push({
          nome: element.escola,
        });
      }

      // Filtrar os valores únicos
      const uniqueSchools = school
      .filter((school, index, self) => index === self.findIndex((s) => s.nome === school.nome))
      .sort((a, b) => {
        if (a.nome > b.nome) {
          return 1;
        }
        if (a.nome < b.nome) {
          return -1;
        }
        return 0;
      }); // Ordena em ordem alfabética

      setEscolas(uniqueSchools);
    }
  }, [data]);

 
  return (
    <div className={classes.widgetWrapper} style={style && { ...style }}>
      <Paper className={classes.paper} classes={{ root: classnames(classes.widgetRoot, { [classes.noWidgetShadow]: noWidgetShadow }) }}>
        <div className={classnames(classes.widgetHeader, { [classes.noPadding]: noHeaderPadding, [headerClass]: headerClass })}>
          {header ? (
            header
          ) : (
            <React.Fragment>
              <Typography variant="h5" color="textSecondary" noWrap>
                {title}
              </Typography>
              {!disableWidgetMenu && (
                <IconButton
                  color="primary"
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={() => setMoreMenuOpen(true)}
                  buttonRef={setMoreButtonRef}
                  style={customButtonStyle} // Estilo personalizado para o botão
                >
                  <MoreIcon />
                </IconButton>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classnames(classes.widgetBody, { [classes.noPadding]: noBodyPadding, [bodyClass]: bodyClass })}>
          {children}
        </div>
      </Paper>
      <Menu
  id="widget-menu"
  open={isMoreMenuOpen}
  anchorEl={moreButtonRef}
  onClose={() => setMoreMenuOpen(false)} // Feche o menu quando uma escola for selecionada
  disableAutoFocusItem
  // anchorOrigin={{
  //   vertical: "bottom",
  //   horizontal: "left",
  // }}
  transformOrigin={{
    vertical: "top",
    horizontal: "left",
  }}
  PaperProps={{
    style: {
      maxHeight: "400px",
      maxWidth: "300px" // Ajuste o tamanho máximo do menu conforme necessário
    },
  }}
>
<div>
      {/* Renderize o campo de busca */}
      {/* <input
  type="text"
  placeholder="Buscar escola..."
  value={termoBusca}
  onChange={(e) => {
    setTermoBusca(e.target.value);
  }}
  className={classes.searchInput}
/> */}

      {/* Renderize a lista de escolas filtrada com base no termo de busca */}
      {escolas != null && escolas.length > 0 ? (
        <div ref={listaRef}>
          {/* {console.log(listaRef)} */}
          {escolas
            .filter((escola) =>
              escola.nome.toLowerCase().includes(termoBusca.toLowerCase())
            )
            .map((escola, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  onEscolaSelect(escola);
                  setMoreMenuOpen(false); // Feche o menu quando uma escola for selecionada
                }}
                 >
                <Typography>{escola.nome}</Typography>
              </MenuItem>
            ))
          }
        </div>
      ) : (
        <MenuItem>
          <Typography>No data available</Typography>
        </MenuItem>
      )}
    </div>
</Menu>
    </div>
  );
}