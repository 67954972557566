import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import logo from '../../images/logoGrande.png';

// styles
import useStyles from "./styles";

// logo

// context
import { useUserDispatch, loginUser, useUserState } from "../../context/UserContext";
function Login(props) {
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  var userDispatch = useUserDispatch();
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [mensagem, setMensagem] = useState('');
  const { loginError } = useUserState();
    
  const handleLogin = async () => {
    setIsLoading(true);
    await loginUser(userDispatch, loginValue, passwordValue, props.history, setIsLoading);
    setIsLoading(false);
  };
  
    useEffect(() => {
      // Função para calcular a mensagem com base no horário
        const agora = new Date();
        const horaAtual = agora.getHours();
        // console.log(horaAtual)
        if (horaAtual >= 5 && horaAtual < 12) {
          setMensagem("Bom Dia!");
        } else if (horaAtual >= 12 && horaAtual < 18) {
          setMensagem("Boa Tarde!");
        } else {
          setMensagem("Boa Noite!");
        }
         
    }, []);
  
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
       </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                Olá, {mensagem}
              </Typography>
              
              <Fade in={loginError}>
              <Typography color="secondary" className={classes.errorMessage}>
               Erro ao logar no sistema!
              </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="E-mail"
                type="email"
                fullWidth
              />
              <TextField
  id="password"
  InputProps={{
    classes: {
      underline: classes.textFieldUnderline,
      input: classes.textField,
    },
  }}
  value={passwordValue}
  onChange={(e) => setPasswordValue(e.target.value)}
  onKeyDown={(e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }}
  margin="normal"
  placeholder="Senha"
  type="password"
  fullWidth
/>
<div class="h-captcha" data-sitekey="3bd1b4c1-0bf4-48fe-947b-2672e2cd15a0"></div>
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                  disabled={
                    loginValue.length === 0 || passwordValue.length === 0
                  }
                  onClick={handleLogin}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Entrar
                </Button>
                )}
                {/* <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                >
                  Esqueceu a Senha?
                </Button> */}
              </div>
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                Welcome!
              </Typography>
              <Fade in={loginError}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
                margin="normal"
                placeholder="Full Name"
                type="text"
                fullWidth
              />
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                  disabled={
                    loginValue.length === 0 || passwordValue.length === 0
                  }
                  onClick={handleLogin}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Entrar
                </Button>
                )}
              </div>
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>or</Typography>
                <div className={classes.formDivider} />
              </div>
              <Button
                size="large"
                className={classnames(
                  classes.googleButton,
                  classes.googleButtonCreating,
                )}
              >
              </Button>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
        © 2019-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.ctdlondrina.com.br/" rel="noopener noreferrer" target="_blank">CTD - Companhia de Tecnologia e Desenvolvimento</a>
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);