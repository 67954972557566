import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  mapContainer: {
    height: "100%",
    margin: -theme.spacing(1) * 3,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    marginTop: "-150px",
  },
  loadingContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flippedImage: {
    animation: "$flip 4s linear infinite",
    width: "250px",
    height: "100px",
    transform: "scaleX(-1)",
    marginBottom: "30px", 
  },
  loadingText: {
    background: `linear-gradient(to bottom, #0587cb, #0959a4, #21b6e6)`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "20px",
    fontWeight: "bold",
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: "pointer",
    color: "#b4d3e4", // Cor do ícone
  },
}));