import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  
  loadingContainer: {
    display: "flex",
    justifyContent: "center", // Centraliza horizontalmente
    alignItems: "center", // Centraliza verticalmente
    height: "100vh", // Preenche toda a altura da tela
    marginTop: "-150px", // Move a imagem para cima
  },
  loadingContent: {
    display: "flex",
    flexDirection: "column", // Coluna para que o texto fique abaixo da imagem
    alignItems: "center",
  },
  flippedImage: {
    animation: "$flip 4s linear infinite", // Aumente a duração se desejar uma animação mais lenta
    width: "250px",
    height: "100px",
    transform: "scaleX(-1)",
    marginBottom: "30px", 
  },
  loadingText: {
    background: `linear-gradient(to bottom, #0587cb, #0959a4, #21b6e6)`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent", // Isso torna o texto transparente para que o gradiente seja visível
    fontSize: "20px",
    fontWeight: "bold",
  },
  "@keyframes flip": {
    "0%, 100%": {
      transform: "scaleX(-1)",
    },
    "50%": {
      transform: "scaleX(1)",
    },
  },
  modalContent: {
    textAlign: "center",
    padding: 20,
  },
  
  modalInput: {
    marginBottom: 10,
  },
  centeredText: {
    textAlign: 'center',
  },
  errorContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#d32f2f', // Vermelho mais escuro
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  errorItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  errorIcon: {
    marginRight: theme.spacing(1),
    fontSize: "1.2em",
    color: theme.palette.common.white, // Texto branco
  },
  errorMessage: {
    color: theme.palette.common.white,
  },
  confirmDialog: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  confirmDialogTitle: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Alinhamento horizontal
    padding: theme.spacing(2),
  },
  confirmDialogActions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  confirmDialogIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
  },
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: theme.zIndex.tooltip + 1,
  },
  alert: {
    borderRadius: "4px",
    textAlign: "center",
    background: "linear-gradient(to bottom, #0587cb, #0959a4, #21b6e6)",
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",  // Alteração aqui para exibir o texto acima da barra
    justifyContent: "center",
    alignItems: "center",
  },
  progressBar: {
    width: "100%",
    borderRadius: "0 0 4px 4px",  // Ajuste para a barra não ter bordas na parte superior
  },
  snackbarContainer: {
    top: '70px', // Ajuste esta propriedade para posicionar o alerta mais para baixo
  },
  
}));
