import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import useStyles from './styles';  // Importe os estilos do arquivo styles.js

const AutoClosingAlert = ({ message, duration, onClose }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [progress, setProgress] = useState(0);
  
    useEffect(() => {
      let timer;
  
      if (open) {
        timer = setInterval(() => {
          setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
        }, duration / 10);
  
        setTimeout(() => {
          clearInterval(timer);
          setOpen(false);
          onClose();
        }, duration);
      }
  
      return () => {
        clearInterval(timer);
      };
    }, [duration, onClose, open]);
  
    return (
      <Snackbar
        open={open}
        autoHideDuration={null}
        onClose={() => {
          setOpen(false);
          onClose();
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={classes.snackbarContainer}
      >
        <div className={classes.alert}>
          {message}
          <LinearProgress className={classes.progressBar} variant="determinate" value={progress} />
        </div>
      </Snackbar>
    );
  };
  
  export default AutoClosingAlert;