// import React, { useState } from "react";
import axios from 'axios'
import {
  Grid,
  } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../tables/TablesDevices";
import { getAuthToken } from "../../context/auth";
import { useEffect, useState } from "react";
import imageLoading from "../../images/logoAzul.png"

let PieChartData = [];

let PieChartDataSupport = []

export default function Dashboard(props) {
  const [dashData, setDashData] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataTickets, setDataTickets] = useState([]);
  const [schoolDeviceOn, setSchoolDeviceOn] = useState(0);
  const [schoolDeviceOff, setSchoolDeviceOff] = useState(0);
  const[school, setSchool] = useState("")
  const handleWidgetClick = () => {
    window.location.href = 'https://suporte.ctdlondrina.com.br/front/ticket.php';
  };
  const updateDashboards = async () => {
    const fetchData = async (url, method, setStateFunction) => {
      const token = getAuthToken();
      
      try {
        const config = {
          method,
          url: url,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios(config);
        const apiData = response.data;
        setStateFunction(apiData);
      } catch (error) {
        console.error('Erro ao fazer a requisição à API:', error);
      }
    };
   
    const fetchAllData = async () => {
    
      // Promessas das chamadas de API
      const promises = [
        fetchData('//dispositivos.ctdlondrina.com.br/api/devices/dashboard', "get", setDashData),
        fetchData('//dispositivos.ctdlondrina.com.br/api/devices/dashboardtickets', "get", setDataTickets),
      ];

      try {
        // Aguarde todas as promessas serem resolvidas antes de definir o loading como falso
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false); // Define o loading como falso após todas as chamadas de API serem concluídas
      }
      
    };
    fetchAllData();
  };
  const updateDataWithDates = async (startDate, endDate) => {
    try {
      // Faz a nova chamada à API com as datas
      const url = '//dispositivos.ctdlondrina.com.br/api/devices/entitydevice';
      const config = {
        method: 'post',
        url,
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
        data: {
          startDate,
          endDate,
        },
      };

      const response = await axios(config);
      const apiData = response.data;
      
      // Atualiza os dados no estado do componente pai
      setDataTable(apiData);
    } catch (error) {
      console.error('Erro ao fazer a requisição do relatório à API:', error);
    }
  };
  useEffect(() => {
    const fetchData = async (url, method, setStateFunction) => {
      const token = getAuthToken();
      try {
        const config = {
          method,
          url: url,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios(config);
        const apiData = response.data;
        setStateFunction(apiData);
      } catch (error) {
        console.error('Erro ao fazer a requisição à API:', error);
      }
      const intervalId = setInterval(() => {
        updateDashboards();
      }, 60000);
  
      // Limpa o intervalo quando o componente é desmontado
      return () => clearInterval(intervalId);
    };
   
    const fetchAllData = async () => {
      setLoading(true); // Inicia o loading no início
  
      // Promessas das chamadas de API
      const promises = [
        fetchData('//dispositivos.ctdlondrina.com.br/api/devices/dashboard', "get", setDashData),
        fetchData('//dispositivos.ctdlondrina.com.br/api/devices/entitydevice', "post", setDataTable),
        fetchData('//dispositivos.ctdlondrina.com.br/api/devices/dashboardtickets', "get", setDataTickets),
      ];

      try {
        // Aguarde todas as promessas serem resolvidas antes de definir o loading como falso
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false); // Define o loading como falso após todas as chamadas de API serem concluídas
      }
    };
  
    fetchAllData();
  }, []);

  const handleEscolaSelect = (escolaSelecionada) => {
    // console.log(escolaSelecionada)
    let deviceSchollOn = 0
    let deviceSchollOff = 0
    
    dataTable.forEach(element => {
      if (element.status === "Ligado" && element.escola === escolaSelecionada.nome) {
        deviceSchollOn++
      } else if (element.status === "Desligado" && element.escola === escolaSelecionada.nome) {
        deviceSchollOff++
      }
    });
    setSchool(escolaSelecionada.nome)
    setSchoolDeviceOn(deviceSchollOn)
    setSchoolDeviceOff(deviceSchollOff)
  };

  const classes = useStyles();
  const theme = useTheme();
  PieChartData =  [
    { name: "Ligados", value: dashData.ligados, color: "success" },
    { name: "Desligados", value: dashData.desligados, color: "warning" },
  ];
  PieChartDataSupport = [
    { name: "Atendidos", value: dataTickets.closeTickets, color: "success" },
    { name: "Em atendimento", value: dataTickets.openTickets, color: "warning" }
  ];

  return (
    <>
      <PageTitle title="Painel" />
      {loading ? (
          <div className={classes.loadingContainer}>
          <div className={classes.loadingContent}>
            <img src={imageLoading} alt="Loading" className={classes.flippedImage} />
            <span className={classes.loadingText}>Carregando...</span>
          </div>
        </div>
            
    
      ) : (
         <Grid container spacing={4}>
      <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget disableWidgetMenu = "false" title="Total de Equipamentos:" upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  <PieChart>
                    <Pie
                      data={PieChartData}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {PieChartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[entry.color].main}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {PieChartData.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                      
                    </div>
                  ))}
                  <div>
                  <Typography className={classes.text} weight="medium" >
                Total: {PieChartData[0].value + PieChartData[1].value}
              </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
        <Widget
  title="Dispositivos por Local:"
  disableWidgetMenu={false}
  upperTitle
  className={classes.card}
  data={dataTable}
  onEscolaSelect={handleEscolaSelect} // Passando a função para lidar com a seleção da escola
>
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  {school}
                </Typography>
                  <Typography size="xl" weight="medium" noWrap >
                    {schoolDeviceOn + schoolDeviceOff}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  Ligados
                </Typography>
                <Typography size="md">{schoolDeviceOn}</Typography>
              </Grid>
              <Grid item xs={5}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography color="text" colorBrightness="secondary" noWrap>
                    Desligados
                  </Typography>
                  <Typography size="md">{schoolDeviceOff}</Typography>
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={12}>
        <a
          href="https://suporte.ctdlondrina.com.br/front/ticket.php"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
        <Widget
            title="Chamados:"
            disableWidgetMenu="false"
            upperTitle
            className={classes.card}
            onClick={handleWidgetClick}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  <PieChart>
                    <Pie
                      data={PieChartDataSupport}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {PieChartDataSupport.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[entry.color].main}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {PieChartDataSupport.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))}
                  <div>
                  <Typography className={classes.text} weight="medium" >
                Total: {PieChartDataSupport[0].value + PieChartDataSupport[1].value}
              </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Widget>
          </a>
        </Grid>
        <Grid item xs={12}>
          <Widget
            noBodyPadding
            bodyClass={classes.tableWidget}
            disableWidgetMenu = "false"
          >
               <Table setDataTable={dataTable} updateDataWithDates={updateDataWithDates} />
          </Widget>
        </Grid>
      </Grid>
      )}
    </>
  );
}