export function setAuthToken(token) {
    localStorage.setItem('id_token', token);
  }
  
  export function getAuthToken() {
    return localStorage.getItem('id_token');
  }
  
  export function removeAuthToken() {
    localStorage.removeItem('id_token');
  }
  
  export function isAuthenticated() {
    const token = getAuthToken();
    return !!token;
  }