import React, { useState } from "react";
import useStyles from "./styles";
import axios from 'axios'
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Grid, Button,Chip, Tooltip, IconButton, TableRow, TableCell,TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logoImage from "../../images/meuLogo.png";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BackupIcon from '@mui/icons-material/Backup';
import moment from 'moment-timezone';
import {getAuthToken } from "../../context/auth";
import MapInModal from "../maps/MapInModal";
import imageLoading from "../../images/logoAzul.png"

export default function Tables({ setDataTable, updateDataWithDates }) {
  const classes = useStyles();
  const datatableReceive = Object.values(setDataTable);
  const [datatableData, setDataTableData] = useState(datatableReceive)
  // console.log(datatableData)
  const [loading, setLoading] = useState(false)
  // const [loadingTable, setLoadingTable] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [mapCoordinates, setMapCoordinates] = useState({ latitude: 0, longitude: 0, dateLocalition: 0 });
  const currentDate = new Date();
  const [endDate, setEndDate] = useState(currentDate.toISOString().split('T')[0]);
  const [filterStateScholl, setFilterStateScholl] = useState([])
  const [filterStateImei, setFilterStateImei] = useState([])
  const [filterStateDevice, setFilterStateDevice] = useState([])
  const [filterStateName, setFilterStateName] = useState([])
  const [filterStateProfile, setFilterStateProfile] = useState([])
  const [apiLoading, setApiLoading] = useState(false);
  
  // const [datatableData,setDatatableData] = useState([])
  // Calcule o startDate como 30 dias antes da data atual
  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [startDate, setStartDate] = useState(thirtyDaysAgo.toISOString().split('T')[0]);
  const [pickerVisible, setPickerVisible] = useState(false);
  const toggleVisibility = () => {
    setPickerVisible(!pickerVisible);
  };

  // useEffect(() => {
  //   setDataTableData(datatableReceive)
  // }, []);
// const handleFilterClick = () => {
//   // Aqui você pode implementar a lógica de filtragem com base nas datas selecionadas.
//   // Atualize o estado ou chame uma função para filtrar os dados.
// };

 // [] como dependência para buscar dados apenas uma vez

 const fetchReportData = async (url, startDate, endDate) => {
  const token = getAuthToken();
  try {
    const config = {
      method: 'post',
      url: url,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        startDate: startDate,
        endDate: endDate,
      },
    };

    const response = await axios(config);
    const apiData = response.data;
    const datatable = Object.values(apiData);
    setDataTableData(datatable);
  } catch (error) {
    console.error('Erro ao fazer a requisição do relatório à API:', error);
  }
};

const generateReport = async () => {
  
  // ... (código existente)

  try {
    setLoading(true);
    // Faz a nova chamada à API com as datas no corpo da requisição
    await fetchReportData('//dispositivos.ctdlondrina.com.br/api/devices/entitydevice', startDate, endDate);

    // Chama a função no componente pai para atualizar os dados
    updateDataWithDates(startDate, endDate);
  } catch (error) {
    console.error('Erro ao gerar relatório:', error);
  } finally {
    setLoading(false);
  }
};

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    setMapCoordinates({
      latitude: 0,
      longitude: 0,
      dateLocalition: null,
    });
  };
    // Função para buscar o deviceId com base no IMEI
    const fetchData = async (imei, setLoading) => {
      const token = getAuthToken();
    
      try {
        setApiLoading(true); // Define o estado de carregamento da API como true ao iniciar a busca de dados
    
        const config = {
          method: 'get',
          url: `//dispositivos.ctdlondrina.com.br/api/devices/localitions/${imei}`,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };
    
        const response = await axios(config);
        const apiData = response.data;
    
        setMapCoordinates({
          latitude: apiData.latitude,
          longitude: apiData.longitude,
          dateLocalition: apiData.updated.time
        });
      } catch (error) {
        console.error('Erro ao fazer a requisição à API:', error);
      } finally {
        setApiLoading(false); // Define o estado de carregamento da API como false após a busca de dados ser concluída (seja com sucesso ou erro)
        setLoading(false); // Define o estado de carregamento como false após a busca de dados ser concluída (seja com sucesso ou erro)
      }
    };
  
  const exportToPDF = () => {
    const horaeDataAtual = new Date();
    const millisecondsDate = horaeDataAtual.getTime();
    const doc = new jsPDF();
  
    doc.addImage(logoImage, "PNG", 10, 10, 60, 30);
    doc.setFontSize(14);
    doc.text("Relatório de Status do Equipamento", 15, 50);
    // console.log(filterStateDevice)
    // Filtrar os dados com base nos filtros selecionados
    const filteredData = datatableData.filter((device) => {
      // Verifique se o dispositivo atende aos critérios de todos os filtros selecionados
      return (
        (filterStateName.length === 0 || filterStateName.includes(device.modelo)) &&
        (filterStateImei.length === 0 || filterStateImei.includes(device.imei)) &&
        (filterStateProfile.length === 0 || filterStateProfile.includes(device.perfil)) &&
        (filterStateScholl.length === 0 || filterStateScholl.includes(device.escola)) &&
        (filterStateDevice.length === 0 || filterStateDevice.includes(device.status))
      );
    });
  
    const data = [];
    
    filteredData.forEach((device) => {
      data.push([
        device.modelo,
        device.imei,
        device.perfil,
        device.escola,
      ]);
  
      device.historico.forEach((evento) => {
        const date = moment.utc(evento.date_converted);
        const formattedDate = date.format('DD/MM/YYYY HH:mm:ss');
        const status = evento.active === 1 ? 'Ligado' : 'Desligado';
        data.push([formattedDate, status]);
      });
      data.push([]);
    });
  
    const columns = [
      { header: "Modelo", dataKey: "modelo" },
      { header: "Imei", dataKey: "imei" },
      { header: "Perfil", dataKey: "perfil" },
      { header: "Escola", dataKey: "escola" },
    ];
  
    doc.autoTable({
      head: [columns.map((column) => column.header)],
      body: data,
      startY: 70,
    });
    doc.save(`PDF_Relatório_de_Uso_${millisecondsDate}.pdf`);
  };
  const exportToCSV = () => {
    const horaeDataAtual = new Date();
    const millisecondsDate = horaeDataAtual.getTime();
  
    // Função para substituir caracteres especiais
    const replaceSpecialChars = (str) => {
      return str
        .replace(/á/g, 'a')
        .replace(/é/g, 'e')
        .replace(/í/g, 'i')
        .replace(/ó/g, 'o')
        .replace(/ú/g, 'u')
        .replace(/ã/g, 'a')
        .replace(/õ/g, 'o')
        .replace(/ç/g, 'c')
        // Adicione mais substituições conforme necessário
        .replace(/[^a-zA-Z0-9]/g, ' '); // Substitui caracteres especiais por _
    };
  
    // Filtrar os dados com base nos filtros selecionados
    const filteredData = datatableData.filter((device) => {
      return (
        (filterStateName.length === 0 || filterStateName.includes(device.modelo)) &&
        (filterStateImei.length === 0 || filterStateImei.includes(device.imei)) &&
        (filterStateProfile.length === 0 || filterStateProfile.includes(device.perfil)) &&
        (filterStateScholl.length === 0 || filterStateScholl.includes(device.escola)) &&
        (filterStateDevice.length === 0 || filterStateDevice.includes(device.status))
      );
    });
  
    const csvContent = [];
  
    // Adicionar cabeçalhos CSV
    csvContent.push(['Modelo', 'Imei', 'Perfil', 'Escola', 'Data', 'Status']);
  
    filteredData.forEach((device) => {
      // Adicionar dados do dispositivo
      csvContent.push([
        replaceSpecialChars(device.modelo),
        replaceSpecialChars(device.imei),
        replaceSpecialChars(device.perfil),
        replaceSpecialChars(device.escola),
        '', // Espaço em branco para a coluna de data
        '', // Espaço em branco para a coluna de status
      ]);
  
      // Adicionar dados do histórico do dispositivo
      device.historico.forEach((evento) => {
        const date = moment.utc(evento.date_converted);
        const formattedDate = date.format('DD/MM/YYYY HH:mm:ss');
        const status = evento.active === 1 ? 'Ligado' : 'Desligado';
  
        csvContent.push(['', '', '', '', formattedDate, status]);
      });
    });
  
    // Converter array CSV para string CSV
    const csvString = csvContent.map(row => row.join(',')).join('\n');
  
    // Criar um elemento <a> para download do arquivo CSV
    const downloadLink = document.createElement('a');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
  
    downloadLink.href = url;
    downloadLink.setAttribute('download', `CSV_Relatorio_de_Uso_${millisecondsDate}.csv`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const renderLocationButton = (value, tableMeta) => {
    const imei = tableMeta.rowData[1];
    return (
      <Button
  variant="outlined"
  color="primary"
  onClick={async () => {
    toggleModal();
    await fetchData(imei, setLoading);
  
  }}
>
  Localizar
</Button>
    );
  };

  const columns = [
    { name: "Modelo", options: { center: true } },
    { name: "Imei", options: { center: true, filter:false } },
    { name: "Perfil", options: { center: true } },
    { name: "Local", options: { center: true } },
    {
      name: "Status",
      options: {
        customBodyRender: (value) => {
          const color = value === "Ligado" ? "primary" : "secondary";
          return <Chip label={value} color={color} />;
        },
        center: true,
      },
    },
    {
      name: "Ultima Localização",
      options: {
        filter: false,
        sort: false,
        customBodyRender: renderLocationButton,
        center: true,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    download: false,
    textLabels: {
      body: {
        noMatch: "Desculpe, dados não encontrados, retorne mais tarde.",
        toolTip: "Classificar",
        columnHeaderTooltip: column => `Classificar por ${column.label}`
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Linhas por Pagina:",
        displayRows: "of",
      },
      toolbar: {
        search: "Procurar",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabelas",
      },
      filter: {
        all: "TODAS",
        title: "FILTROS",
        reset: "RESETAR FILTROS",
      },
      viewColumns: {
        title: "Ver Colunas",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onFilterChange: (changedColumn, filterList) => {
      const [
        filterName,
        filterImei,
        filterProfile,
        filterScholl,
        filterDevice,
      ] = filterList;
      // console.log(filterImei)
    
      setFilterStateName(filterName || []);
      setFilterStateImei(filterImei || []);
      setFilterStateProfile(filterProfile || []);
      setFilterStateScholl(filterScholl || []);
      setFilterStateDevice(filterDevice || []);
    },
    print: "false",
    headerStyle: {
      textAlign: "center",
    },
    
    selectableRows: "none",
    customToolbar: () => {
      return (
   <>
   
   
   <Tooltip title={"Exportar para PDF"}>
        <IconButton onClick={exportToPDF}>
          <PictureAsPdfIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Exportar para CSV"}>
        <IconButton onClick={exportToCSV}>
          <BackupIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Filtrar Por Periodo"}>
        <IconButton onClick={toggleVisibility}>
          <CalendarMonthIcon />
        </IconButton>
      </Tooltip>
      {pickerVisible && (
  <>
    <TextField
      type="date"
      label="Data de Início"
      value={startDate}
      onChange={(e) => setStartDate(e.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      margin="dense"
      style={{ marginRight: '10px' }} // Adiciona margem direita
    />

    <TextField
      type="date"
      label="Data de Fim"
      value={endDate}
      onChange={(e) => setEndDate(e.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      margin="dense"
      style={{ marginRight: '10px' }} // Adiciona margem direita
    />

    <Button
      variant="contained"
      color="primary"
      onClick={()=>{
        generateReport()
        toggleVisibility()
      }}
      style={{ marginRight: '10px', width: '200px', height: '50px' }}
    >
      Filtrar por Periodo
    </Button>
  </>
)}
   </>
      
  
         );
    },
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const rowIndex = rowMeta.dataIndex;
      const historico = datatableData[rowIndex].historico.map((evento, index) => {
        const date = moment.utc(evento.date_converted);
        const formattedDate = date.format('DD/MM/YYYY HH:mm:ss');
        const status = evento.active === 1 ? 'Ligado' : 'Desligado';
    
        return (
          <div key={index}>
            {`${formattedDate} : ${status}`}
          </div>
        );
      });
    
      return (
        <TableRow>
          <TableCell colSpan={8}>{historico}</TableCell>
        </TableRow>
      );
    },
  };

  return (
    <>
      <Grid item xs={12}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <div className={classes.loadingContent}>
              <img src={imageLoading} alt="Loading" className={classes.flippedImage} />
              <span className={classes.loadingText}>Carregando Novos Dados...</span>
            </div>
          </div>
        ) : (
          <MUIDataTable
            title="Listagem de dispositivos"
            data={datatableData.map((device) => [
              device.modelo,
              device.imei,
              device.perfil,
              device.escola,
              device.status,
            ])}
            columns={columns}
            options={options}
          />
        )}
      </Grid>
      <MapInModal
        key={mapCoordinates.dateLocalition}
        latitude={mapCoordinates.latitude}
        longitude={mapCoordinates.longitude}
        dateLocalition={mapCoordinates.dateLocalition}
        open={isModalOpen}
        onClose={toggleModal}
        apiLoading={apiLoading}
      />
    </>
  );
}
