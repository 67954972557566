import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  MenuItem,
  InputAdornment,
  IconButton,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import axios from 'axios'
import useStyles from "./styles";
import { getAuthToken } from "../../context/auth";
import imageLoading from "../../images/logoAzul.png"
import WarningIcon from '@mui/icons-material/Warning';
const AlterUserInModal = ({ userId, open, onClose, onChangesSaved }) => {
  const classes = useStyles();

  // Estados para armazenar os valores modificados
  const [modifiedName, setModifiedName] = useState("");
  const [modifiedEntitie, setModifiedEntitie] = useState("");
  const [modifiedProfile, setModifiedProfile] = useState("");
  const [loading, setLoading] = useState(false)
  const [dataUser, setDataUser] = useState("")
  const [dataEntities, setDataEntities] = useState([])
  const [selectedProfile, setSelectedProfile] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");
  // const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [modifiedPassword, setModifiedPassword] = useState("");
  const [modifiedConfirmPassword, setModifiedConfirmPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleUpdate = async () => {
    const token = getAuthToken();
    // Limpa mensagens de erro anteriores
    setErrorMessages([]);
  
  
      // Verifique se algum dado foi modificado
      if (
        modifiedName !== "" ||
        modifiedEntitie !== "" ||
        modifiedProfile !== "" ||
        modifiedPassword !== "" ||
        modifiedConfirmPassword !== ""
      ) {
        // Pergunta de confirmação ao usuário
  
 
          const dataToUpdate = {
            id: userId,
            name: modifiedName,
            entitie: modifiedEntitie,
            profile: modifiedProfile,
            password: modifiedPassword,
            confirmPassword: modifiedConfirmPassword,
            // Adicione outros campos conforme necessário
          };
          const config = {
            url: '//dispositivos.ctdlondrina.com.br/api/users/',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          };
  
          axios.put(config.url, dataToUpdate, config)
          .then(async (response) => {
            // Manipule a resposta da API conforme necessário
            // console.log('Resposta da API:', response.data);
            setPassword("")
            setConfirmPassword("")
            setModifiedConfirmPassword("")
            setModifiedPassword("")
            setModifiedName("");
            setModifiedEntitie("")
            setModifiedProfile("");
            setConfirmDialogOpen(false)
            try {
              // Tente fechar o modal
              await onClose();
              onChangesSaved();
              // console.log('Modal fechado com sucesso');
            } catch (error) {
              console.error('Erro ao fechar o modal:', error);
            }
          })
          .catch((error) => {
            // Manipule os erros da API e atualize mensagens de erro
            console.error('Erro na chamada da API:', error);
            if (error.response && error.response.data && error.response.data.errors) {
              setErrorMessages(error.response.data.errors);
            } else {
              setErrorMessages(["Erro ao processar a solicitação. Tente novamente mais tarde."]);
            }
          });
        } else {
          // console.log("Atualização cancelada pelo usuário.");
        }
   
  };
  
  useEffect(() => {
    const token = getAuthToken();
    const fetchData = async (url, method, setStateFunction) => {
      // console.log(`Fetching data from ${url} with method ${method}`);
      
      try {
        const config = {
          method,
          url: url,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios(config);
        const apiData = response.data;

        // Verifique se a URL contém a string desejada
        if (url.includes('//dispositivos.ctdlondrina.com.br/api/devices/entities')) {
          // console.log("chegou aqui");
          
          setStateFunction(apiData);
        } else {
          // console.log(apiData)
          // Corrigindo as chamadas de função para atualizar os estados
          setSelectedProfile(apiData[0].profile);
          setSelectedEntity(apiData[0].entity_name);
          setDataUser(apiData[0].name)
          setUserEmail(apiData[0].email)
          
        }
      } catch (error) {
        console.error('Erro ao fazer a requisição à API:', error);
      }
    };
   
    const fetchAllData = async () => {
      setLoading(true);

      try {
        const userUrl = `//dispositivos.ctdlondrina.com.br/api/users/${userId}`;
        await fetchData(userUrl, "get", setDataUser);

        const entitiesUrl = '//dispositivos.ctdlondrina.com.br/api/devices/entities';
        await fetchData(entitiesUrl, "get", setDataEntities);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();

  }, [userId, open]);

  
   // Função para lidar com a atualização
   const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleUpdateConfirmation = () => {
     setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    
  };

 
  
  // console.log("Loading:", loading);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Editar Usuário</DialogTitle>
      <div className={classes.modalContent}>
        {/* Se loading for verdadeiro, mostra o indicador de loading */}
        {loading ? (
          <div className={classes.loadingContainer}>
            <div className={classes.loadingContainer}>
              <div className={classes.loadingContent}>
                <img src={imageLoading} alt="Loading" className={classes.flippedImage} />
                <span className={classes.loadingText}>Carregando...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
         {errorMessages.length > 0 && (
  <div className={classes.errorContainer}>
    {errorMessages.map((errorMessage, index) => (
      <div key={index} className={classes.errorItem}>
        <span className={classes.errorIcon}>⚠️</span>
        <span className={classes.errorMessage}>{errorMessage}</span>
      </div>
    ))}
  </div>
)}
<Dialog
  open={confirmDialogOpen}
  onClose={handleConfirmDialogClose}
  className={classes.confirmDialog} // Adicione esta linha
>
  <DialogTitle className={classes.confirmDialogTitle} id="alert-dialog-title">
    <WarningIcon className={classes.confirmDialogIcon} />
    Confirmação de Alteração
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      {`Confirma a alteração do usuário ${modifiedName}?`}
    </DialogContentText>
  </DialogContent>
  <DialogActions className={classes.confirmDialogActions}>
    <Button onClick={handleConfirmDialogClose} color="primary">
      Cancelar
    </Button>
    <Button onClick={handleUpdate} color="primary" autoFocus>
      Confirmar
    </Button>
  </DialogActions>
</Dialog>

  <TextField
  id="email"
  InputProps={{
    classes: {
      underline: classes.textFieldUnderline,
      input: classes.textField,
    },
  }}
  value={userEmail}
  margin="normal"
  label="E-mail"
  type="email"
  fullWidth
  disabled // Desabilita a edição
/>

<TextField
  id="name"
  value={dataUser}
  onChange={(e) => {
    setDataUser(e.target.value);
    setModifiedName(e.target.value);
  }}
  
  margin="normal"
  label="Nome"
  fullWidth
  className={`${classes.modalInput} ${classes.centeredText}`}
/>

<TextField
        id="password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setModifiedPassword(e.target.value);
        }}
        margin="normal"
        label="Senha"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        id="confirmPassword"
        type={showConfirmPassword ? 'text' : 'password'}
        value={confirmPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
          setModifiedConfirmPassword(e.target.value);
        }}
        margin="normal"
        label="Confirmar Senha"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={handleToggleConfirmPasswordVisibility}
                edge="end"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

<TextField
  id="profile"
  select
  value={selectedProfile}
  onChange={(e) => {
    setSelectedProfile(e.target.value);
    setModifiedProfile(e.target.value);
  }}
  margin="normal"
  label="Perfil"
  fullWidth
  className={classes.modalInput}
>
  <MenuItem value="Administrador">Administrador</MenuItem>
  <MenuItem value="Usuario">Usuário</MenuItem>
</TextField>

<TextField
  id="entity"
  select
  value={selectedEntity}
  onChange={(e) => {
    setSelectedEntity(e.target.value);
    setModifiedEntitie(e.target.value);
  }}
  margin="normal"
  label="Entidade"
  fullWidth
  className={classes.modalInput}
>
  {dataEntities.map((entity) => (
    <MenuItem key={entity.id} value={entity.name}>
      {entity.name}
    </MenuItem>
  ))}
</TextField>
          </>
        )}
      </div>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button onClick={handleUpdateConfirmation} color="primary">
          Atualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlterUserInModal;